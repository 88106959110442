/* https://fonts.google.com/specimen/Lora?selection.family=Lora */
@import url('https://fonts.googleapis.com/css?family=Raleway|Open Sans|Roboto');

html,body {
  margin:0; height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 100%;
  /* overflow: hidden;  skjule scrollbar. må gjlre dette kun på "skjerm"  */
}

/* LOGIN-FORM *****************************************************************/
.loginform {  background: rgba(54, 25, 25, .8);  width: 400px;  margin-top: 100px;  margin-left: auto;  margin-right: auto;  padding: 30px;  color: #fff;}
.loginform input {  display: block;  margin: 10px 0;  width: 100%;}
.loginform button {  float: right; }

.knapp { cursor: pointer; }

/* HEADER */
/*#header { background-color: #333;  }
#header h1, #meny li { float:left;}
#header h1 { font-family: 'Acme', serif; color: #fff; font-size: 1.8em; margin: 11px 20px; }
#meny {  list-style-type: none;  margin: 0;  padding: 0;  overflow: hidden;  }
#meny li a {  display: block;  color: #666;  text-align: center;  padding: 18px 16px;  text-decoration: none; }
#meny li a:hover {  background-color: #111; }
#informasjon { float: right; color: #aaa; margin: 18px 20px; font-size: 0.8em; cursor: pointer; }
*/
#version { font-size: 9px; position:fixed; left: 10px; bottom: 10px; }

/* KONTROLL / FORHÅNDSVISNING */

#forhandsvisning-container { position: relative; width: 320px; }
#forhandsvisning { font-size: .44em; height: 171px; width: 304px; border: 2px solid #444; margin: 8px; }
#forhandsvisningForklaring { position: absolute; top: 0px; width: 320px; text-align: center; display: flex; justify-content:center;  align-content:center;  flex-direction:column; 
    height: 187px; background-color: rgba(0, 0, 0, 0.726); visibility: hidden; font-size: 1.2em; }
#forhandsvisningForklaring a { color: #ffffff; }
#forhandsvisning-container:hover #forhandsvisningForklaring {visibility: visible }


/* SKJERM */
#skjerm { font-size: 4em; position: relative; height: 100%; width: 100%; background-color: #000; color: #fff; overflow: hidden; }
#skjerm.inside-iframe { zoom: 0.16; }

#pausebilde {  position: absolute; top: 0; left: 0; width: 100%; height: 100%; 
    background-repeat: no-repeat; background-size: cover; background-position: center; 
    animation: pulse 1s ease-in 0s alternate 1 none running;  }

#skjerm_sangtekst { 
  height: 100%; text-align: center; display: flex; justify-content:center;  align-content:center;  flex-direction:column;
  white-space: pre; 
  /*animation: pulse1 1s ease-in 0s alternate 1 none running;*/
  text-shadow: 2px 2px 0px #111, 1px 1px 2px #111;
  animation: fade-in-with-rotate 1s ease-out 1 both; }
#skjerm_sangtekst::after { 
  animation: pulse2 1s ease-in 0s alternate 1 none running; } 

#skjerm_bakgrunnsfarge { 
  position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  animation: pulse 1s ease-in 0s alternate 1 none running; }

/* BAKGRUNN 1 */

  .bakgrunn-1::before,
  .bakgrunn-1::after {
      content: '';
      position: absolute;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      filter: blur(60px);
      opacity: 0.3;
      animation: move 10s infinite alternate;
  }
  
  /* Første bevegelige sirkel */
  .bakgrunn-1::before {
      background-color: rgba(255, 99, 71, 0.7); /* Lys rødfarge */
      top: 10%;
      left: 20%;
      animation-delay: 0s;
  }
  
  /* Andre bevegelige sirkel */
  .bakgrunn-1::after {
      background-color: rgba(30, 144, 255, 0.7); /* Lys blåfarge */
      bottom: 15%;
      right: 25%;
      animation-delay: 1s;
  }
  
  /* Animasjonen for bevegelse */
  @keyframes move {
      0% {
          transform: translate(0, 0) scale(1);
      }
      100% {
          transform: translate(50px, 50px) scale(10);
      }
  }

  /* BAKGRUNN 2 */

  .bakgrunn-2::before {
    --size: 750px;
    --speed: 30s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    z-index: -200;
    content: '';
      position: absolute;
    top: 30%;
    left: 30%;
    bottom: 30%;
    right: 30%;
    filter: blur(calc(var(--size) / 2));
    background-image: linear-gradient(#032984, #26decc);
    animation: rotate var(--speed) var(--easing) alternate infinite;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  }
   @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* YOUTUBE */

#skjerm_youtube { position:absolute; top:0; left:0; width: 100%; height: 100%;
  animation: pulse 1s ease-in 0s alternate 1 none running;}

@keyframes pulse { 
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes pulse2 { 
  0% { opacity: 0.8; }
  50% { opacity: 0.5; }
  100% { opacity: 0.8; }
}

@keyframes fade-in-with-rotate{
  from{
      opacity: 0;
      transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }
  to{
      opacity: 1;
      transform: perspective(500px) translate3d(0, 0, 0);
  }
}


#skjerm_trekning { 
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  color: white;
  background-color: black; 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#skjerm_trekningx { 
  border: 0px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 500px;
  transform: translate(-50%, -50%);
  padding: 10px;
}

#trekning_tall {
  font-size: 7em;
  text-align: center;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
           0px 8px 13px rgba(0,0,0,0.1),
           0px 18px 23px rgba(0,0,0,0.1);
  animation: 2s cubic-bezier(0.16, 1, 0.3, 1) 1s  forwards anime;
  background-color: rgba(22, 47, 49, 0.9);
  border-radius: 80px;
  padding: 0 100px;
  
}

@keyframes anime {
  0% {
      transform: translate(-20%, -20%) scale(100);
  }
  10% {
      opacity: 1;
  }
  100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
  }
}